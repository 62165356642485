<template>
  <el-result
    icon="error"
    title="Error Tip"
    sub-title="Please follow the instructions"
  >
    <template #extra>
      <el-button type="primary" size="medium" @click="$router.push('/')">Back</el-button>
    </template>
  </el-result>
</template>